import * as React from "react";
import { withRouter } from "react-router";
import { GET_ARTICLE, GET_CURRENT_NUMERO } from "../../../../Queries";
import { Query } from "react-apollo";
import { withApollo } from "react-apollo";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Component } from "react";
import animations from "../Chapitre/animations";
import Content from "./Content";
import classNames from "classnames";
import Player from "../Chapitre/Player";

import { SettingsContext } from "../../../Magazine/MagazineSettings";

class ContentPlayer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            display: false,
            ref: null
        };
    }

    setReference = (ref) => {
        this.ref = ref;
    };

    componentDidMount() {
        if(this.ref){
            animations.ArticleEnter(this.ref);
        }
    }

    componentWillUnmount() {
        if(this.ref) {
            animations.ArticleLeave(this.ref);
        }
    }

    findArticlesToChapitre(articles, numero) {
        if(!articles) {
            this.props.history.push("/"); return null;
        }
        return articles.filter((article) => article.category.slug === this.props.match.params.chapitre);
    }

    findChapitre(chapitres) {
      if(!chapitres) {
          this.props.history.push("/"); return null;
      }
      return chapitres.find((chapitre) => chapitre.slug === this.props.match.params.chapitre);
    }

    getArticleWithQuery(articles, numero) {

        if(!articles) {
            this.props.history.push("/"); return null;
        }

        let article = articles.filter((article) => article.slug === this.props.match.params.slug)[0];

        if(!article) {
            return null;
        }

        return article;

    }

    render() {
        let { match, display, toggleMenu } = this.props;

        if(!this.props.match.params.slug && !this.props.match.params.chapitre ) return null;

        const classes = classNames({
            'player-module': true,
            'active': display,
        });

        return (
            <Query query={ GET_CURRENT_NUMERO }>
                {({loading, error, data, client}) => {

                    if(error) return null;
                    if(loading) return null;

                    let currentNumero = data.currentNumero;

                    let articles = currentNumero.articles;
                    let categories = currentNumero.categories;

                    let articles_chapitre = this.findArticlesToChapitre(articles, currentNumero);

                    const category = this.findChapitre(categories);
                    
                    return(
                        <SettingsContext.Consumer>
                            {({ fonts }) => (
                                <div className={ classes }>
                                    <Player match={ match } className={ classes }
                                            category={category}
                                            articles={ articles_chapitre }
                                            toggleMenu={ toggleMenu }
                                            categories={ categories }
                                            allArticles={ articles }
                                            numero={ currentNumero }
                                            fonts={ fonts }
                                    />
                                </div>
                            )}
                        </SettingsContext.Consumer>
                    );

                }}
            </Query>
        )
    }
}

ContentPlayer.contextTypes = {
    magazine: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        render: state.renders.article
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        renderArticle: () => {
            dispatch({
                type: 'RENDER_ARTICLE'
            });
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(ContentPlayer)));
