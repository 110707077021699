import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Query } from "react-apollo";
import classNames from "classnames";

import { SettingsContext } from "../../../../Magazine/MagazineSettings";
import { GET_CURRENT_NUMERO } from "../../../../../Queries";
import { withRouter } from "react-router-dom";
import Socials from "./Socials";
import { SearchBtnView } from "./views/SearchBtn";

let supportsTouch, clientWidth, clientHeight;

if(typeof window !== "undefined"){
    supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    const body = document.body;

    if(supportsTouch) body.classList.add("touch");

    clientWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    clientHeight = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
}

let FontAwesomeIcon;

const Loading = () => null;

const NumeroInfo = ({ numero, fonts }) => {

    let date = new Date(numero.date);
    let dateParsed = Date.parse(numero.date);

    let options = { year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <div className="numeroInfo">
            <a href="https://www.ridmi.io" target="_blank" className="ridmi-link">
                <div className="logo" />
            </a>
        </div>
    )
};

const MenuNumeroIconOn = () => {
    return <i className="ico-klesia-kiosque"/>;
};

const MenuIconOn = () => {
    return <i className="ico-klesia-menu"/>;
};

class LateralBarView extends Component {

    render() {

        const { scrolled, socials, numero, toggleMenu, menuShow, toggleMenuShow, fonts, isPage, logo, noImg } = this.props;

        const classes = classNames({
            'menuShow': menuShow,
            'menu-toggle-btn': true
        });

        const classesNumero = classNames({
            'menu-numero-toggle-btn': true,
            'active': this.props.menuNumero,
        });

        const classesLateral = classNames({
            'menuShow': menuShow,
            'lateralBarView': true,
            'isSearching': this.props.search.isSearching,
            'noImg': noImg,
            'scrolled': scrolled || isPage
        });

        return (
            <div className={ classesLateral }>
                <div className="buttons">
                    <button className={ classesNumero } onClick={ () => {
                        this.props.toggleMenuNumero();
                        this.props.setMenuInner(false);
                        toggleMenu(false) }
                    }>
                        <MenuNumeroIconOn />
                    </button>
                    <button className={ classes } onClick={ () => {
                        toggleMenu(); toggleMenuShow();
                        this.props.setMenuInner(false);
                        this.props.setMenuNumero(false) }
                    }>
                        <MenuIconOn />
                    </button>
                    <SearchBtnView
                        history={ this.props.history }
                        setMenuNumero={ this.props.setMenuNumero }
                        setMenuInner={ this.props.setMenuInner }
                        isLateralBar={ true }
                        toggleMenu={ toggleMenu }
                        toggleSearch={ this.props.toggleSearch } search={ this.props.search }
                    />
                </div>
                <div className="menu">
                </div>
                <div className="numero-lateral">
                    {/*<a href="https://www.klesia-arrco.fr/" target="_blank">
                        <div className="logo-klesia" />
                    </a>*/}
                    <div className="numero-socials">
                        <Socials socials={ socials } logo={ logo }/>
                    </div>
                    <div className="byridmi">
                        <i className="ico-klesia-byridmi">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                        </i>
                    </div>
                    {/*<div className="numero-info">
                        <i className="icon-byridmi" />
                        <NumeroInfo numero={ numero } fonts={ fonts } menuShow={ menuShow }/>
                    </div>*/}
                </div>
            </div>
        )


    }
}


class LateralBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menuShow: this.props.menuShow
        }
    }

    toggleMenuShow = () => {

        this.setState((prevState) => {
            return {
                menuShow: !prevState.menuShow
            }
        });

        if(this.state.menuShow){
            this.props.captureMenuClick('close');
        } else {
            this.props.captureMenuClick('open');
        }

    };

    render() { let that = this;
        return (
            <Query query={ GET_CURRENT_NUMERO }>
                {({loading, error, data, client}) => {

                    if (loading) return null;
                    if (error) return `Error! ${error.message}`;
                    if(!data.currentNumero) that.forceUpdate();

                    return (
                        <SettingsContext.Consumer>
                            {({ couleur, fonts, socials }) => {

                                return(
                                    <LateralBarView
                                        socials={ socials }
                                        numero={ data.currentNumero }
                                        noImg={ this.props.noImg }
                                        couleur={ couleur }
                                        setMenuInner={ this.props.setMenuInner }
                                        toggleMenuNumero={ this.props.toggleMenuNumero }
                                        menuNumero={ this.props.menuNumero }
                                        setMenuNumero={ this.props.setMenuNumero }
                                        isPage={ this.props.isPage }
                                        toggleMenu={ this.props.toggleMenu }
                                        toggleSearch={ this.props.toggleSearch }
                                        menuShow={ this.props.menuShow }
                                        search={ this.props.search }
                                        toggleMenuShow={ this.toggleMenuShow }
                                        scrolled={ this.props.scrolled }
                                        fonts={ fonts }
                                    />
                                )
                            }}
                        </SettingsContext.Consumer>
                    )
                }}
            </Query>
        )
    }
}

const mapStateToProps = (state, props) => {

    return {
        scrolled: state.scrolled.scrolled,
        search: state.search,
        view: state.view.kind,
        noImg: state.view.noImg,
        menuNumero: state.menuNumero.active,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        captureMenuClick: (value) => {
            dispatch({
                type: "CLICK_MENU",
                value: value
            })
        },
        toggleMenuNumero: () => {
            dispatch({
                type: "TOGGLE_MENU_NUMERO"
            })
        },
        toggleSearch: () => {
            dispatch({
                type: "TOGGLE_SEARCH"
            })
        },
        setMenuInner: (value) => {
            dispatch({
                type: "TOGGLE_MENU_INNER_VALUE",
                value
            })
        },
        setMenuNumero: (value) => {
            dispatch({
                type: "SET_MENU_NUMERO",
                value
            })
        },
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LateralBar));
