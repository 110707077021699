import * as React from "react";
import classNames from "classnames";
import { PureComponent } from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import { withApollo } from "react-apollo";
import {SvgCircleOuter} from "./Circle";

let supportsTouch, clientWidth;

if(typeof window !== "undefined"){
    supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

    clientWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
}

function truncate(string, length){
    if (string.length > length)
        return string.substring(0, length) + '...';
    else
        return string;
}

class Timeline extends PureComponent {

    constructor(props){
        super(props);

        this.state = {
            x: 0,
            hovering: false,
            hoveringArticle: false,
            articleHover: null,
            chapitreHover: null,
            coorHover: {
                x: 0,
                y: 0
            },
            positionCurrent: "0px",
            chapitre: {}
        };

        this.ref = "";
        this.refInner = "";
    }

    findChapitre(chapitres) {
        return chapitres.filter((chapitre) => chapitre.slug === this.props.match.params.chapitre)[0];
    }

    findFirstArticleToChapitre(articles, chapitre) {

        // need to sort with position value
        return articles.filter((article) => article.category.slug === chapitre.slug)[0];

    }

    hoverChapitre(del, chapitre = null, e, index = null){

        if(typeof window !== "undefined"){
            supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
            if(supportsTouch) return null;
        }

        if(del){
            this.setState({
                hovering: false
            });
        } else {

            let rect = this.refInner.getBoundingClientRect();

            let parent = this.refInner.getBoundingClientRect();
            let children = document.querySelector('.chapitre-point[data-key="' + chapitre._id + '"]').getBoundingClientRect(),
                relativePos = {};

            relativePos.left = children.left - parent.left;

            this.setState({
                hovering: true,
                coorHover: {
                    x: relativePos.left,
                    y: e.pageY
                },
                chapitreHover : chapitre
            });

        }

    }

    stateTimeline = ()  => {

        if(this.ref) {
            this.setState({
                width: this.getTimelineWidth()
            });

            setTimeout(() => {
                this.setState({
                    positionCurrent: this.getCurrentPosition()
                });
            }, 10);

        }
    };

    getCurrentPosition() {

        if(this.refInner) {

            let parent = this.refInner.getBoundingClientRect();
            let children = document.querySelectorAll('.article.current')[0].getBoundingClientRect(),
                relativePos = {};

            relativePos.left = children.left - parent.left;

            return relativePos.left;

        } return null;

    }

    componentDidMount() {

        this.setState({
            width: this.getTimelineWidth(),
        });

        setTimeout(() => {
            this.setState({
                positionCurrent: this.getCurrentPosition()
            });
        }, 10);


        window.addEventListener('resize', this.stateTimeline);
    }

    componentDidUpdate(previousProps, previousState) {

        setTimeout(() => {
            let position = this.getCurrentPosition();

            if(previousState.positionCurrent !== position){

                this.setState({
                    positionCurrent: position
                });
            }
        }, 10);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.stateTimeline, false);
    }

    getTimelineWidth() {
        return this.ref.offsetWidth;
    }

    isActiveChapitre(currentChapitre, chapitre) {
        return (currentChapitre.slug === chapitre.slug)
    }

    isActiveArticle(article) {
        if(article && this.props.article)
            return (this.props.article.slug === article.slug);
    }

    isBeforeActiveChapitre(currentChapitre, chapitreObj, chapitres) {

        let result = false;

        if(chapitreObj.order < currentChapitre.order){
            result = true;
        }

        return result;

    }

    setRef(ref) {
        this.ref = ref;
    }

    setRefInner(ref) {
        this.refInner = ref;
    }

    findArticlesToChapitre(articles, chapitre) {
        return articles.filter((article) => article.category.slug === chapitre.slug);
    }

    renderTimeline(x_old, nb_articles) { let that = this;

        const length = this.props.categories.length;
        const articles = this.props.articles;
        const chapitre = this.findChapitre(this.props.categories);

        let x = x_old;

        this.props.categories.some(function(category, index) {

            const nb_articles_chapitre = that.findArticlesToChapitre(that.props.articles, category).length;
            x += x_old + (that.state.width / (length) * (nb_articles_chapitre / nb_articles));
            const article = that.findFirstArticleToChapitre(articles, category);

            return that.isActiveChapitre(chapitre, category);
        });

        let width = x + 5 + "px";

        return {
            length,
            articles,
            chapitre,
            width
        };

    }


    getNumberArticles(chapitres) {
        let nb_articles = 0;

        chapitres.forEach((chapitre) => {
            nb_articles += this.findArticlesToChapitre(this.props.articles, chapitre).length;
        });

        return nb_articles;
    }

    getWidthChapitre(category, index, length, nb_articles) {

        const nb_chapitres = length;
        const nb_articles_chapitre = this.findArticlesToChapitre(this.props.articles, category).length;

        return (this.state.width / (length + 1)) * ((nb_articles_chapitre / nb_articles) * nb_chapitres);

    }

    getXChapitre(category, index, length, nb_articles, x_old) {

        const nb_chapitres = length;
        const nb_articles_chapitre = this.findArticlesToChapitre(this.props.articles, category).length;

        // return x_old + (this.state.width / (length + 1) * (nb_articles_chapitre / nb_articles))
        return x_old + (this.state.width / length * (nb_articles_chapitre / nb_articles));
    }

    renderCategoryTimeline(category, index, length, nb_articles, width_x, articles, chapitre) {

        const passed = this.isBeforeActiveChapitre(chapitre, category, this.props.categories);

        const classes = classNames({
            'current': this.isActiveChapitre(chapitre, category),
            'passed': passed
        });

        let x = this.getXChapitre(category, index, length, nb_articles, width_x);

        return {
            x,
            widthChapitre:  this.getWidthChapitre(category, index, length, nb_articles),
            widthX: x - width_x,
            width_x: width_x + x,
            article: this.findFirstArticleToChapitre(articles, category),
            classes,
            articlesChapitre: this.findArticlesToChapitre(articles, category),
            passed
        }

    }

    isBeforeActiveArticle(currentChapitre, chapitreObj, chapitres, activeArticle, currentArticle) {

        if(activeArticle)
        {
            let result = false;

            if(chapitreObj.order < currentChapitre.order){
                result = true;
            } else if(chapitreObj.order === currentChapitre.order) {
                if(currentArticle.positionChapitre < activeArticle.positionChapitre){
                    result = true;
                }
            }

            return result;
        }

    }

    hoverArticle(e, article = null, reverse = true, index) {

        if(typeof window !== "undefined"){
            supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
            if(supportsTouch) return null;
        }

        if(!reverse){
            this.setState({
                hoveringArticle: false
            });
        } else {

            let parent = this.refInner.getBoundingClientRect();
            let children = document.querySelector('a.article[data-key="' + article._id + '"]').getBoundingClientRect(),
                relativePos = {};

            relativePos.left = children.left - parent.left;

            this.setState({
                hoveringArticle: true,
                coorHover: {
                    x: relativePos.left,
                    y: e.pageY
                },
                articleHover : article
            });

        }

    }

    renderTitle(title) {

        if(typeof window !== "undefined") {
            if (clientWidth < 321) {
                return truncate(title, 20)
            } else if(clientWidth < 426){
                return truncate(title, 22)
            } else if(clientWidth < 900){
                return truncate(title, 35)
            }
            return truncate(title, Infinity)
        } else {
            return truncate(title, Infinity)
        }

    }


    render() {
        let width_x = 0;
        const nb_articles = this.getNumberArticles(this.props.categories);

        const { length, articles, chapitre, width } = this.renderTimeline(width_x, nb_articles);
        const style = { width: this.state.positionCurrent + 12 };
        let articlesChapitre = this.findArticlesToChapitre(articles, chapitre);

        if(!this.props.article && !this.props.category) return null;

        if(!this.props.article && this.props.category){
            articlesChapitre = [this.props.category];
        }

        if(typeof window !== "undefined") {
            if(clientWidth < 426){

                return(
                    <div className="timeline" ref={(ref) => this.setRef(ref) }>
                        <div className="chapitre-title" style={ this.props.fonts.family4 }
                             onClick={ this.props.toggleMenu }>
                            <span className="number">
                                <SvgCircleOuter
                                    category={ chapitre }
                                    user={ this.props.user }
                                    articles={ articlesChapitre }
                                    min={ 100 }
                                    datas={ { width: 25, height: 25 }}
                                    max={ 0 }
                                />
                            </span>
                            <span className="title" style={ this.props.fonts.family2 }>
                                { this.renderTitle((this.props.article || this.props.category).title) }
                            </span>
                        </div>
                    </div>
                );
            }
        }

        return(
            <div className="timeline" ref={(ref) => this.setRef(ref) }>
                <div className="chapitre-title" style={ this.props.fonts.family4 } onClick={ this.props.toggleMenu }>
                    <span className="number">
                        <SvgCircleOuter
                            category={ chapitre }
                            user={ this.props.user }
                            articles={ articlesChapitre }
                            min={ 100 }
                            max={ 0 }
                        />
                    </span>
                    <span className="title" style={ this.props.fonts.family1 }>
                        { this.renderTitle((this.props.article || this.props.category).title) }
                    </span>
                </div>
            </div>
        )
    }

}


const mapStateToProps = (state, props) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        captureTimelineClickArticle: (title) => {
            dispatch({
                type: "CLICK_TIMELINE_ARTICLE",
                title: title
            })
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(Timeline )));
