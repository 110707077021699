import React, {Component, PureComponent} from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";
import IsRead from "./IsRead";
import classNames from "classnames";

class Header extends Component {

    constructor(props){
        super(props);
        this.state = {};
        this.props.changeKindView("article");
    }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.article.slug !== this.props.article.slug || this.props.user !== nextProps.user){
            return true;
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.article.slug !== this.props.article.slug){
            if(typeof window.scrollTo !== "undefined"){
                window.scrollTo(0,0);
            }
            this.props.setArticleLastRead(this.props.article, this.props.numero);
            this.props.setArticleLastReadAll(this.props.article, this.props.numero);
        }
    }

    componentDidMount() {
        if(typeof window.scrollTo !== "undefined"){
            window.scrollTo(0,0);
        }

        this.props.setArticleLastRead(this.props.article, this.props.numero);
        this.props.setArticleLastReadAll(this.props.article, this.props.numero);
    }

    getInfosCible = (isOnlyRead = false, key) => { let { article, fonts } = this.props;

        let classes = "outer-i " + ((isOnlyRead) ? "is-only-read" :  "");

        return (
            <div className="infos" key={ article._id + key}>
                <div className="infos-inner" data-text="true">

                    {article.category && !isOnlyRead &&
                        <div className="category-block">
                            <div className="number" 
                                 style={{ backgroundColor: article.category.couleur, ...fonts.family4 }}>
                                { article.category.number }
                            </div>
                            <div className="inner-titles" style={{color: article.category.couleur}}>
                                <span>{ article.category.title }</span>
                                {article.custom.sous_chapitre &&
                                <span className="subcategory" style={ fonts.family4 }
                                >&nbsp;|&nbsp;{ article.custom.sous_chapitre.title }</span>
                                }
                            </div>
                        </div>
                    }

                    <div className={ classes }>
                        <div className="time" style={ fonts.family4 }>
                            <i className="icon-time" />
                            <span className="inner">
                               { article.timeForRead }mn
                            </span>
                        </div>
                        <IsRead
                            unsetArticleReaded={ this.props.unsetArticleReaded }
                            setArticleReaded={ this.props.setArticleReaded }
                            article={ article }
                            typeData={ (article.category) ? 'article' : 'chapitre' }
                            fonts={ fonts }
                            user={ this.props.user }
                            numero={ this.props.numero }
                            isCouverture={ true } />
                    </div>
                </div>
            </div>
        )
    };

    getHeaderCible = (isWallpaper) => { let { article, fonts } = this.props;

        let style = {
            fontFamily: fonts.family1.fontFamily
        }

        if(!isWallpaper.url) {
            style.marginBottom = "1rem";
        }

        if(article.couleur_titre) {
          style.color = article.couleur_titre;
        }

        return [
            this.getInfosCible(false, 1),
            <h1 style={ style } data-text="true"
                key={ article._id + "_h1"}>
                { article.title }
            </h1>,
            this.getInfosCible(true, 3),
        ]
    };

    getTopValueView() {
        if(typeof window === "undefined") return "0px";
        if(!document.querySelector("header.header")) return "0px";
        let header = document.querySelector("header.header").getBoundingClientRect().height;
        return header + "px";
    }

    render() { let that = this;

        let { article } = this.props;

        let style = {
            marginTop: this.getTopValueView(),
        };

        return(
            <header className="articleHeader" style={ style }>
                { this.getHeaderCible(article.wallpaper) }
                {article.wallpaper && article.wallpaper.url && <ImageComponent image={ article.wallpaper }/>}
            </header>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        currentArticle: state.currentArticle,
        user: state.user
    };

};

const mapDispatchToProps = (dispatch) => {
    return {
        setArticleLastRead: (article, numero) => {
            dispatch({
                type: 'USER_SET_LAST_READ_V3',
                article,
                numero
            });
        },
        setArticleLastReadAll: (article, numero) => {
            dispatch({
                type: 'USER_SET_LAST_READ_ALL',
                article,
                numero
            });
        },
        startRead: () => {
            dispatch({
                type: 'COMMENCER_LECTURE'
            });
        },
        continueRead: () => {
            dispatch({
                type: 'REPRENDRE_LECTURE'
            });
        },
        captureCouvRead: () => {
            dispatch({
                type: 'CLICK_LECTURE_UNE'
            });
        },
        setArticleReaded: (article, typeData, numero) => {
            dispatch({
                type: 'USER_SET_ARTICLE_READ',
                article,
                typeData,
                numero
            });
        },
        unsetArticleReaded: (article) => {
            dispatch({
                type: 'USER_UNSET_ARTICLE_READ',
                article,
            });
        },
        setPreviewArticle: (article) => {
            dispatch({
                type: 'SET_PREVIEW_ARTICLE',
                article,
            });
        },
        changeKindView: (kind) => {
            dispatch({
                type: 'CHANGE_VIEW',
                kind,
            });
        },
    };
};

Header.contextTypes = {
    magazine: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(Header)));

export class ImageComponent extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            imageStatus: false
        };
    }

    handleImageLoaded() {
        this.setState({ imageStatus: true });
    }

    handleImageErrored() {
        this.setState({imageStatus: false});
    }

    componentDidUpdate(prevProps) {
        if(prevProps.image.url !== this.props.image.url){
            this.setState({
                imageStatus: false
            })
        }
    };

    render() {

        let classes = classNames({
            'loaded': this.state.imageStatus
        });

        let classesFx = classNames({
            'fx-img': true,
            'loaded': this.state.imageStatus
        });

        let propsObj = {
            className: classes,
            src: this.props.image.url,
            onLoad: this.handleImageLoaded.bind(this),
            onError: this.handleImageErrored.bind(this)
        };

/*        if(this.props.image.width)
            propsObj.width = this.props.image.width;
        if(this.props.image.height)
            propsObj.height = this.props.image.height; */

        return (
            <div className="imageOuter">
                <img {...propsObj} />
                <div className={ classesFx } />
            </div>
        );
    }

}
