import React, { Component, PureComponent } from 'react';
import { Link } from "react-router-dom";
import classNames from "classnames";
import 'react-perfect-scrollbar/dist/css/styles.css';

import { SettingsContext } from "../../../../../Magazine/MagazineSettings";
import { GET_CURRENT_NUMERO, GET_MENU_NUMEROS } from "../../../../../../Queries";
import { Query } from "react-apollo";
import PropTypes from "prop-types";

import SearchBtn from "./SearchBtn";

import { ScrollContext } from "../../../../../Scroll/Scroll";
import { Accessibilite } from "../../../Accessibilite/Accessibilite";

let supportsTouch, clientWidth;

if(typeof window !== "undefined"){
    supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

    clientWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
}

function truncate(input, length) {
    if(!input) return "";
    if (input.length > length)
        return input.substring(0, length) + '...';
    else
        return input;
}

class NumeroMenu extends Component {

    constructor(props){
        super(props);

        this.state = {
            menuShow: false,
            isPerfect: false
        }

    }

    toggleNumeroMenu = (toggle = null, numero = null) => {

        if(toggle === false)
            this.setState(() => {
                return {
                    menuShow: false
                }
            });
        else {
            this.setState((prevState) => {
                return {
                    menuShow: !prevState.menuShow
                }
            })
        }
    };

    fetchMore(){

        this.props.fetchMore({
            variables: {
                offset: this.props.numeros.length
            },
            updateQuery: (prev, { fetchMoreResult }) => {

                if (!fetchMoreResult) return prev;

                return Object.assign({}, prev, {
                    numeros: [...prev.numeros, ...fetchMoreResult.numeros]
                });

            }
        });

    }

    getCurrentNumero(numeros, current) {
        return numeros.find((numero) => numero._id === current._id)
    }

    goTo = (e, link, numero, current) => {
        e.preventDefault();

        if(current._id !== numero._id) {
            this.props.toggleMenu(false, numero);
        } else {
            this.props.toggleSearch();
        }

    };

    render() {

        let length = this.props.numeros.length;

        const classes = classNames({
            'menu-numero': true,
            'menuShow': this.state.menuShow,
        });

        return(
            <SettingsContext.Consumer>
                {({ fonts }) => (
                    <Query query={ GET_MENU_NUMEROS } variables={{
                        magazine_slug: this.context.magazine.slug,
                    }} fetchPolicy="cache-only">
                        {({loading, error, data, updateQuery, client, networkStatus}) => {
                            let numeros = data.datas.menuNumeros;

                            const classes2 = classNames({
                                'numeros-menu': true,
                                'show-more': (numeros.length > 3)
                            });

                            return(
                                <Query query={ GET_CURRENT_NUMERO }>
                                    {({loading, error, data, client}) => {
                                        let current = data.currentNumero;

                                        return (
                                            <div className={ classes } data-text="true">

                                                <Link to={`/imprimer`}>
                                                    Imprimer
                                                </Link>

                                                <button className="menu-numeros-btn"
                                                        style={ fonts.family3 } onClick={ this.toggleNumeroMenu }>
                                                    <div className="inner">
                                                        <span>{ (clientWidth < 769) ? truncate(current.title, 20) : truncate(current.title, 45)}</span>
                                                        {!this.state.menuShow && <i className="ico-klesia-chevron-down" />}
                                                        {this.state.menuShow && <i className="ico-klesia-chevron-up" />}
                                                    </div>
                                                </button>

                                                {this.state.menuShow && <div className="numeros-menu-outer" style={ fonts.family4 }>
                                                    {!this.state.isPerfect &&
                                                        <div className="inner-numero-menu">
                                                        <ul className={ classes2 }>
                                                            { numeros.map((numero) => {

                                                                if(numero._id === current._id){
                                                                    return (
                                                                        <li className="current" key={numero._id}>
                                                                            <Link to={`/${numero.slug}`}
                                                                                  onClick={(e) => this.goTo(e, `/${numero.slug}`, numero , current) }>
                                                                                {numero.title} <i className="icon-check" />
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                }

                                                                return (
                                                                    <li key={numero._id}>
                                                                        <Link to={`/${numero.slug}`} onClick={() => { this.props.toggleMenu(false, numero) } }>
                                                                            {numero.title}
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            }) }
                                                        </ul>
                                                    </div>
                                                    }
                                                </div>}
                                            </div>
                                        )
                                    }}
                                </Query>
                            );
                        }}
                    </Query>
                )}
            </SettingsContext.Consumer>
        )
    }



}

NumeroMenu.contextTypes = {
    magazine: PropTypes.object,
    resetCache: PropTypes.bool
};

class HeaderScrollContext extends PureComponent {
    render() {

        let { isSearching, isPage, menu, scrollDirection, kindView, menuNumero, fixTimelineBar } = this.props;

        const classes = classNames({
            'scrollUp': (scrollDirection === "up" || fixTimelineBar),
            'scrollDown': (scrollDirection === "down" && !fixTimelineBar),
            'header': true,
            'isSearching': isSearching,
            'isPage': isPage,
            'menuShow': menu,
            'menuNumeroShow': menuNumero,
            'articleView': kindView === "article",
            'couvertureView': kindView === "couverture"
        });

        return this.props.children({
            classes
        });
    }
}

class HeaderView extends PureComponent {

    goTo = (e, link, numero, current) => {
        e.preventDefault();

        if(current._id !== numero._id) {
            this.props.toggleMenu(false, numero);
        } else {
            this.props.toggleMenu();
        }

    };

    renderTitle(title) {

        if(typeof window !== "undefined") {
            if (clientWidth < 321) {
                return truncate(title, 20)
            } else if(clientWidth < 426){
                return truncate(title, 25)
            } else if(clientWidth < 900){
                return truncate(title, 35)
            }
            return truncate(title, Infinity)
        } else {
            return truncate(title, Infinity)
        }

    }


    render() {

        let { updateWidthScroll,
            setMenuNumero,
            menu,
            match,
            toggleMenu,
            data,
            scrollFX,
            fetchMore,
            menuNumero,
            magazine, currentNumero, logo, fonts,
            isPage, openLink, history, currentArticles,
            menuShow, menuInnerShow,
            isSearching, kindView, isNoImg, menuInner,
            openLinkAndLoading, client, user,
            scrollDirection, setMenuInner, fixTimelineBar
        } = this.props;

        return (
            <HeaderScrollContext {...this.props}>
                {({ classes }) => {
                    return(
                        <header className={ classes }>
                            <div className="inner">

                                <div className="center-bloc">
                                    <span className="numeroBloc">
                                        { menu && <span><span className="title">{currentNumero.title}</span>
                                            <span className="pipe">&nbsp;&nbsp;|&nbsp;&nbsp;</span><span className="sub">Sommaire</span></span>}
                                        { menuNumero && <span style={{ display: 'none' }}>Le kiosque</span>}
                                        { isSearching && <span>Recherche</span>}
                                        { (!menuNumero && !isSearching && (kindView === "article") && !menu) &&
                                        <Link to={`/${currentNumero.slug}`} className="title">
                                            {this.renderTitle(currentNumero.title)}
                                        </Link>
                                        }
                                    </span>
                                </div>

                                <div className="left-bloc">
                                    {menuNumero && (user.lastArticlesRead.length > 0) &&
                                        <span onClick={() => setMenuNumero(false) }>
                                            <i className="ico-klesia-arrow-left-rounded"/>
                                            <span className="title-nav">Retour à la lecture</span>
                                        </span>
                                    }
                                    {menuShow && !menuInnerShow && (user.lastArticlesRead.length > 0) &&
                                        <span onClick={() => toggleMenu(false) }>
                                            <i className="ico-klesia-arrow-left-rounded"/>
                                            <span className="title-nav">Retour à la lecture</span>
                                        </span>
                                    }
                                    {menuInnerShow && (user.lastArticlesRead.length > 0) &&
                                        <span onClick={() => setMenuInner(false) }>
                                            <i className="ico-klesia-arrow-left-rounded"/>
                                            <span className="title-nav">Retour à la lecture</span>
                                        </span>
                                    }
                                    {isSearching && (user.lastArticlesRead.length > 0) &&
                                        <span onClick={() => toggleMenu(false) }>
                                            <i className="ico-klesia-arrow-left-rounded"/>
                                            <span className="title-nav">Retour à la lecture</span>
                                        </span>
                                    }
                                    {kindView === "article" && !isSearching && !menuInnerShow && !menuShow && !menuNumero &&
                                        <span onClick={() => toggleMenu() }>
                                            <i className="ico-klesia-arrow-left-rounded"/>
                                            <span className="title-nav">Sommaire</span>
                                        </span>
                                    }
                                </div>

                                <div className="right-bloc">
                                    {data.numeros &&
                                    <div>
                                        {menuShow && currentNumero.document && <a href={currentNumero.document}><i className="ico-klesia-print" /></a>}
                                        <Accessibilite fonts={ fonts } font={ fonts.family1 } />
                                        <SearchBtn
                                            isPage={ isPage } numeros={ data.numeros }
                                            toggleMenu={ toggleMenu }
                                            menuShow={ menuShow }
                                            menuNumero={ menuNumero }
                                            setMenuInner={ setMenuInner }
                                            setMenuNumero={ setMenuNumero }
                                            slug={ currentNumero.slug } history={ history }
                                        />
                                    </div>}
                                </div>

                            </div>
                            <ScrollBloc scrollFX={ scrollFX } />
                        </header>
                    )
                }}
            </HeaderScrollContext>
        )
    }

}

class ScrollFX extends Component {

    constructor(props){
        super(props);

        this.ref = React.createRef();

        this.state = {
            width: "",
        };

        this.handleScroll = this.handleScroll.bind(this);

    }

    handle = () => { let that = this;
        if(typeof window !== "undefined"){
            requestAnimationFrame(that.handleScroll);
        }
    };

    componentDidMount() { let that = this;
        document.addEventListener('scroll', that.handle, false);
    }

    componentWillUnmount() { let that = this;
        document.removeEventListener("scroll", that.handleScroll, false);
    }

    handleScroll() {

        if(typeof window !== "undefined") {

            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

            let height = document.body.scrollHeight - document.documentElement.clientHeight;
            let widthCalcul = (scrollTop / height) * 100;
            this.props.updateWidthScroll(widthCalcul, scrollTop);
        }

    }

    render() {

        const style = {
            transform: "scaleX(" + this.props.scrollWidth/100 + ")"
        };

        return(
            <div className="scroll-fx" style={ style }  ref={ this.ref } />
        )
    }

}

export default HeaderView;


class ScrollBloc extends PureComponent {

    render() {

        let { scrollFX } = this.props;

        return(
            <div className="scroll-block"><div/>
                <div className="scroll-inner">
                    <ScrollContext.Consumer>
                        {({ scrollWidth, changeScrollWidth }) => {
                            return(
                                <ScrollFX updateWidthScroll={ changeScrollWidth }
                                          scrollWidth={scrollWidth}
                                          scrollFX={ scrollFX }/>
                            )
                        }}
                    </ScrollContext.Consumer>
                </div>
            </div>
        )
    }
}
